import React, { useState, useEffect } from "react";
import "./style.scss";
import { Modal, Table, Input, Tag } from "antd";
import moment from "moment";
import Loader from "react-loader-spinner";
import AudioPlayer from "../AudioPlayer";
import { ColumnProps } from "antd/es/table";
const { Search } = Input;

type Props = {
  onChange?: any;
  visible?: boolean;
  loading?: boolean;
  onCancel?: any;
  resultData?: Array<Object>;
  productObj?: any;
  surveyObj?: any;
};

const SurveyResultModal = (props: Props) => {
  const {
    loading,
    visible,
    onCancel,
    resultData,
    productObj,
    surveyObj,
  } = props;

  const [name, setName] = useState("");
  const [dataSource, setDataSource] = useState(resultData);
  useEffect(() => {
    setDataSource(resultData);
  }, [resultData]);

  const columnsData: ColumnProps<any>[] = [
    {
      title: "Tên khảo sát",
      dataIndex: "survey.name",
      key: "1",
      width: 80
    },
    {
      title: "Bác sĩ",
      dataIndex: "doctor.profile.fullName",
      key: "2",
      width: 80
    },
    {
      title: "Thời gian",
      dataIndex: "updatedAt",
      key: "4",
      render: (text, record) => (
        <>
          <div>{moment(record.timeStart).format("DD/MM/YYYY")}</div>
          <div style={{ fontSize: 13 }}>
            {moment(record.timeStart).format("HH:mm") +
              " - " +
              moment(record.timeEnd).format("HH:mm")}
          </div>
        </>
      ),
      width: 50
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      sorter: (a, b) => b.status.length - a.status.length, //Sort theo độ dài của status: SUCCESSED & FAILED
      render: (status) => (
        <span>
          <Tag color={status === "SUCCESSED" ? "green" : "volcano"}>
            {status === "SUCCESSED" ? "Thành công" : "Thất bại"}
          </Tag>
        </span>
      ),
      width: 50
    },
    {
      title: "Ghi âm",
      dataIndex: "pathRecord",
      align: "left",
      render: (pathRecord) => pathRecord ? <AudioPlayer pathRecord={pathRecord}/> : <>Không có ghi âm</>,
      width: 120
    }
  ];

  const change_alias = (alias: string) => {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  };

  return (
    <div className="modal-result">
      <Modal
        title={
          productObj && surveyObj === undefined
            ? `Kết quả khảo sát của sản phẩm ` + productObj.name
            : `Kết quả khảo sát cho khảo sát ` + surveyObj.name
        }
        centered
        visible={visible}
        cancelText="Đóng"
        onCancel={onCancel}
        maskClosable
        okButtonProps={{ style: { display: "none" } }}
        // footer={null}
        // width={984}
        className="modal-survey"
      >
        <Search
          value={name}
          placeholder="Tìm theo tên Bác sĩ"
          onChange={(e) => {
            const currValue = change_alias(e.target.value);
            const searchKey = e.target.value;
            const dataSourceFor: any = dataSource;
            setName(searchKey);
            const filteredData = dataSourceFor.filter((entry: any) =>
              change_alias(entry.doctor.profile.fullName).includes(currValue)
            );
            if (currValue !== "") {
              setDataSource(filteredData);
            } else {
              setDataSource(props.resultData);
            }
          }}
          style={{ width: 200, marginBottom: 12 }}
        />
        {loading ? (
          <div className="location">
            <Loader type="Oval" color="#0073c4" height={50} width={50} />
          </div>
        ) : (
          <Table
            dataSource={dataSource}
            columns={columnsData}
            // pagination={{
            //   defaultPageSize: 5,
            //   showSizeChanger: false,
            //   pageSizeOptions: ["5", "10", "25"]
            // }}
            pagination={false}
            scroll={{ x: "calc(300px + 50%)", y: 400 }}
          ></Table>
        )}
      </Modal>
    </div>
  );
};

export default SurveyResultModal;
