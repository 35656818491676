import React, { useState, useEffect } from "react";
import "./style.scss";
import { Button } from "antd";
import CustomInput from "../../components/Input";
import AuthenticationService from "../../services/authentication.api";

type Props = {
  setStage: any;
};

const LoginPage = (props: Props) => {
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");


  useEffect(() => {
    localStorage.clear();
  },[])

  const login = async () => {
    const loginData = {
      salt: username
    };
    AuthenticationService.login(loginData).then(res => {
      if (res.code !== -1) {
        localStorage.setItem("salt_token", res.data[`api-token`]);
        localStorage.setItem("medRep", res.data.user._id);
        localStorage.setItem("fullName", res.data.user.profile.fullName);
        props.setStage(1);
      } else {
        setMessage("Đăng nhập thất bại, vui lòng thử lại!");
      }
    });
  };

  return (
    <div className="login-container">
      <div className="login-body">
        <div className="login-page">
          <h3>Chào mừng đến với</h3>
          <h1>
            <span className="besins-color">BESINS</span> Khảo sát sản phẩm
          </h1>
        </div>
        <div className="login-form">
          <div>
            <CustomInput
              label={"Mã nhân viên"}
              placeholder={"Nhập mã nhân viên"}
              size={"large"}
              type={"text"}
              value={username}
              onChange={setUsername}
            />
          </div>
          <p style={{ color: "#F45B19", marginTop: 4 }}>{message}</p>
          <div style={{ marginTop: "32px" }}>
            <Button onClick={login} type="primary" block>
              Đăng nhập
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
