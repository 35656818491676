import axios from "axios";
import HOST from "../variables/host";

export default function callLoginApi(
  endpoint,
  method = "GET",
  data = null,
  form_data = false
) {
  return axios({
    method: method,
    url: `${HOST}/${endpoint}`,
    data: data
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
      if (!err.response) {
        // window.location.href = "/login";
      } else {
        const status = err.response.data.status;
        if (status === 403 && window.location.href !== "/login")
          window.location.href = "/login?redirect=true";
      }
      throw err;
    });
}
