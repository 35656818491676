import React, { useState, useEffect } from "react";
import * as typeformEmbed from "@typeform/embed";
import CustomInput from "../../components/Input";
import OTPInput from "../../components/OTPInput";
import { Button, Row, Col, Spin } from "antd";
import "./style.scss";
import { useParams } from "react-router-dom";
import ProductService from "../../services/product.api";
import LogService from "../../services/log.api";
import moment from "moment";
import DoctorService from "../../services/doctor.api";
import Loader from "react-loader-spinner";
import AuthenticationService from "../../services/authentication.api";
import { refresh } from "../../variables/image";

const SurveyOnlinePage = (props) => {
  const { survey } = useParams();
  const [showLayout, setShowLayout] = useState(true);
  const [countdown, setCountdown] = useState("");
  const [isCountdown, setIsCountdown] = useState(true);
  const [isSurveyEnd, setIsSurveyEnd] = useState(false);

  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [product, setProduct] = useState();
  const [phoneNum, setPhoneNum] = useState("");
  const [doctor, setDoctor] = useState();
  const [doctorName, setDoctorName] = useState();
  const [surveyCode, setSurveyCode] = useState("");

  const [errPhone, setErrPhone] = useState("");

  const [otp, setOtp] = useState("");
  const [errOtp, setErrOtp] = useState("");
  const [otpValues, setOTPValues] = useState([]);

  const [isChecked, setChecked] = useState(false);
  const [isSentAgain, setIsSentAgain] = useState(false);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSurvey, setIsShowSurvey] = useState(false);
  const [isCheckLoading, setIsCheckLoading] = useState(false);
  const [typeTopup, setTypeTopup] = useState("");

  const checkPhone = async (e) => {
    e.preventDefault();
    try {
      const data = {
        surveyId: survey,
        phone: phoneNum,
      };
      const res = await DoctorService.checkPhone(data);
      setIsCheckLoading(true);
      if (res.code === 1) {
        localStorage.setItem("salt_token", res.data.token);
        //Send again
        setIsSentAgain(!isSentAgain);
        setTimeout(() => {
          setIsSentAgain(false);
        }, 10000);

        sendOtp();
        setDoctorName(res.data.profile.fullName);
        setDoctor(res.data._id);
        setChecked(true);
        setErrPhone("");
        setIsCheckLoading(false);
      } else {
        setErrPhone("Số điện thoại của bác sĩ không hợp lệ!");
        setIsCheckLoading(false);
        throw res;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitOtp = async () => {
    try {
      const data = {
        phone: phoneNum,
        code: otp,
      };
      const res = await AuthenticationService.checkOTP(data);
      if (res.code === 1) {
        startSurvey();
      } else {
        throw res;
      }
    } catch (err) {
      setOtp("");
      setOTPValues(["", "", "", "", "", ""]);
      setErrOtp("Sai mã OTP, vui lòng nhập lại");
    }
  };

  const startSurvey = () => {
    setIsShowSurvey(true);
    setMessage("Đang tải khảo sát");
    setIsLoading(true);
    const data = {
      doctor: doctor,
      survey: survey,
      product: product,
      timeStart: moment().toISOString(),
      phone_OTP: phoneNum,
      code: surveyCode,
      location: null,
    };

    var surveyUrl = `https://besinssurvey.typeform.com/to/${surveyCode}?drname=${doctorName}&phoneotp=${phoneNum}`;

    LogService.save_log(data).then((res) => {
      // const embedElement = document.querySelector(".typeform-content");
      // typeformEmbed.makeWidget(embedElement, surveyUrl, {
      //   hideHeaders: true,
      //   hideFooter: true,
      //   // buttonText: "Bắt đầu",
      //   onSubmit: function () {
      //     submitLog(res.data._id);
      //   },
      // });
      const embedElement = document.querySelector(".typeform-content");
        typeformEmbed.makeWidget(embedElement, surveyUrl, {
          hideHeaders: false,
          hideFooter: false,
          onSubmit: function () {
            submitLog(res.data._id);
          },
        });
    });
  };

  const sendOtp = async () => {
    try {
      await AuthenticationService.getOTPSurvey({ phone: phoneNum });
      setIsSentAgain(!isSentAgain);
      setOtp("");
      setOTPValues(["", "", "", "", "", ""]);
      setTimeout(() => {
        setIsSentAgain(false);
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  };

  const submitLog = (id) => {
    let timeEnd = moment().toISOString();
    const data = {
      timeEnd: timeEnd,
    };
    props.setTimeEnd(timeEnd);
    if (typeTopup === "TOPUP" || typeTopup === "NONE" || typeTopup === "BOOK_GIVING" || typeTopup === "GIFT_GIVING" || typeTopup === "NOTE_GIVING" ) {
      LogService.submitLog(id, data).then((res) => {
        setIsShowSurvey(false);
        props.setStage(2);
      });
    }
    if (typeTopup === "CARD") {
      window.history.pushState("", "", `?survey_id=${id}`);
      props.setStage(1);
    }
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await ProductService.get_survey_info(survey);
        setProduct(res.product._id);
        setSurveyCode(res.code);
        setTimeEnd(res.timeEnd);
        setTimeStart(res.timeStart);
        setTypeTopup(res.topupMethod)
        console.log(res.topupMethod)
      } catch (err) {
        console.log(err);
      }
    };
    getProduct();
  }, [product, survey]);

  useEffect(() => {
    var countDownDate = new Date(timeStart).getTime();
    var countDownTimeEndDate = new Date(timeEnd).getTime();
    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      var distanceTimeEnd = countDownTimeEndDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      distance &&
        setCountdown(
          days + "d " + hours + "h " + minutes + "m " + seconds + "s "
        );

      // If the count down is over, write some text

      if (distance < 0) {
        setIsCountdown(false);
      }

      if (distanceTimeEnd < 0) {
        setIsSurveyEnd(true);
        clearInterval(x);
      }
    }, 1000);
    setTimeout(() => {
      setShowLayout(false);
    }, 1500);
  }, [timeEnd, timeStart]);

  return (
    <div className="survey-online-page">
      {!showLayout ? (
        <div>
          {isShowSurvey && <div className="typeform-content"></div>}
          {isSurveyEnd && (
            <div className="product-page-container">
              <div className="product-page-content">
                <div className="product-page">
                  <h1>
                    <span className="besins-color">BESINS</span> Khảo sát sản
                    phẩm
                  </h1>
                  <p className="besins-description">
                    Phần Trả lời câu hỏi đã kết thúc.
                  </p>
                </div>
              </div>
            </div>
          )}
          {isCountdown && (
            <div className="product-page-container">
              <div className="product-page-content">
                <div className="product-page">
                  <h1>
                    <span className="besins-color">BESINS</span> Khảo sát sản
                    phẩm
                  </h1>
                  <p className="besins-description">
                    Phần Trả lời câu hỏi chưa bắt đầu. <br /> Link này chỉ được
                    kích hoạt ngay sau khi kết thúc thảo luận
                  </p>
                  {/* <p className="besins-countdown">
                {moment(timeStart).format("DD/MM/YYYY HH:mm")}
              </p> */}
                </div>
              </div>
            </div>
          )}
          {!isCountdown && !isSurveyEnd && (
            <div className="product-page-container">
              <div className="product-page-content">
                <div className="product-page">
                  <h1>
                    <span className="besins-color">BESINS</span> Khảo sát sản
                    phẩm
                  </h1>
                  <p className="besins-description">
                    Nhập các trường thông tin để bắt đầu khảo sát
                  </p>
                  <div className="product-form">
                    <form onSubmit={checkPhone}>
                      <Row gutter={32}>
                        <Col md={24} xs={24} style={{ paddingRight: 0 }}>
                          <CustomInput
                            label={"Số điện thoại"}
                            placeholder={"Ví dụ: 090xxxxxx"}
                            size={"large"}
                            type={"text"}
                            value={phoneNum}
                            onChange={setPhoneNum}
                            disabled={isChecked}
                          />
                        </Col>
                        <Col md={24} xs={24} style={{ paddingRight: 0 }}>
                          <Button
                            size="large"
                            className="btn-otp"
                            style={{ margin: "10px 0 0", width: "100%" }}
                            block
                            onClick={checkPhone}
                            disabled={phoneNum.length < 10 || isSentAgain}
                          >
                            {isCheckLoading ? (
                              <Loader
                                type="Oval"
                                color="#0073c4"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <span className="send-otp">
                                {!isChecked ? (
                                  "Kiểm tra và gửi OTP"
                                ) : (
                                  <>
                                    <img
                                      src={refresh}
                                      alt=""
                                      style={{ marginRight: "10px" }}
                                    />
                                    Gửi lại OTP
                                  </>
                                )}
                              </span>
                            )}
                          </Button>
                          {isChecked && (
                            <span
                              className="phone-desc"
                              style={{ color: "green" }}
                            >
                              Số điện thoại hợp lệ!
                            </span>
                          )}
                          {errPhone && (
                            <span
                              className="phone-desc"
                              style={{ color: "red" }}
                            >
                              {errPhone}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </form>
                    <div id="recaptcha-container"></div>
                    <Row style={{ marginTop: 28 }}>
                      <OTPInput
                        label="Nhập OTP"
                        length={6}
                        className="otpContainer"
                        inputClassName="otpInput"
                        isNumberInput
                        autoFocus
                        error={errOtp !== ""}
                        errorText={errOtp}
                        value={otp}
                        otpValues={otpValues}
                        setOTPValues={setOTPValues}
                        onChangeOTP={setOtp}
                        disabled={!isChecked}
                      />
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                      <Button
                        disabled={otp.length < 6}
                        onClick={() => submitOtp()}
                        type="primary"
                        block
                      >
                        Khảo sát
                      </Button>
                      {isLoading && (
                        <div className="location">
                          <Loader
                            type="Oval"
                            color="#0073c4"
                            height={20}
                            width={20}
                          />
                          <div className="location-text">{message}</div>
                        </div>
                      )}
                      {/* <div className="location-text">{message}</div> */}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spin spinning={showLayout} tip="Loading...">
          <div className="typeform-content"></div>
        </Spin>
      )}
    </div>
  );
};

export default SurveyOnlinePage;
