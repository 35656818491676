import React, { useState, useEffect } from "react";
import CustomSelect from "../../components/Select";
import SurveyResultModal from "../../components/Modal";
import { Button, Row } from "antd";
import "./style.scss";
import ProductService from "../../services/product.api";
import Loader from "react-loader-spinner";
import moment from "moment";
import LogService from "../../services/log.api";
import * as typeformEmbed from "@typeform/embed";
import HOST_URL_IMAGE from "../../variables/hostUrl";
import getUrlParam from "../../utils/getUrlParam.js";

const ProductSurveyPage = (props) => {
  const [product, setSelectProduct] = useState();
  const [productObj, setProductObj] = useState({});
  const [surveyObj, setSurveyObj] = useState({});

  const [message, setMessage] = useState("");

  const [survey, setSelectSurvey] = useState();
  const [surveyList, setSurveyList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSurvey, setIsShowSurvey] = useState(false);
  const [logMessage, setLogMessage] = useState("");
  const [resultList, setResultList] = useState([]);

  const [isShowResult, setIsShowResult] = useState(false);
  const [typeTopup, setTypeTopup] = useState("");

  const showPosition = (position) => {
    var surveyObj = surveyList.find((item) => item._id === survey);
    setIsLoading(false);

    const data = {
      medRep: localStorage.getItem("medRep"),
      doctor: props.doctor,
      survey: survey,
      product: product,
      timeStart: moment().toISOString(),
      phone_OTP: props.phoneOtp,
      code: surveyObj.code,
      channel: props.channel,
      location: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
    };
    var surveyUrl = `https://besinssurvey.typeform.com/to/${
      surveyObj.code
    }?mrname=${localStorage.getItem("fullName")}&drname=${
      props.doctorName
    }&locationx=${data.location.latitude}&locationy=${
      data.location.longitude
    }&phoneotp=${props.phoneOtp}`;

    LogService.save_log(data).then((res) => {
      if (res.code === -1) {
        // localStorage.clear();
        props.setStage(1);
      } else {
        const embedElement = document.querySelector(".typeform-content");
        typeformEmbed.makeWidget(embedElement, surveyUrl, {
          hideHeaders: false,
          hideFooter: false,
          onSubmit: function () {
            submitLog(res.data._id);
          },
        });
      }
    });
  };

  const submitLog = (id) => {
    let timeEnd = moment().toISOString();

    const data = {
      timeEnd: timeEnd,
    };
    props.setTimeEnd(timeEnd);
    if (typeTopup === "TOPUP" || typeTopup === "NONE" || typeTopup === "BOOK_GIVING"  || typeTopup === "GIFT_GIVING" || typeTopup === "NOTE_GIVING") {
      LogService.submitLog(id, data).then((res) => {
        setIsShowSurvey(false);
        window.location.href = `?survey_id=${id}`;
        props.setStage(4);
      });
    }
    if (typeTopup === "CARD") {
      window.history.pushState("", "", `?survey_id=${id}`);
      props.setStage(3);
    }
  };

  const error = () => {
    setMessage("Không thể truy xuất vị trí của bạn");
  };

  const getLocation = () => {
    let latitude_mobile = getUrlParam()["x"];
    let longtitude_mobile = getUrlParam()["y"];
    setIsShowSurvey(true);

    if (latitude_mobile && longtitude_mobile) {
      setIsLoading(true);
      setMessage("Đang lấy vị trí hiện tại của bạn");
      showPosition({
        coords: {
          latitude: parseFloat(latitude_mobile),
          longitude: parseFloat(longtitude_mobile),
        },
      });
    } else if (navigator.geolocation) {
      setIsLoading(true);
      setMessage("Đang lấy vị trí hiện tại của bạn");
      navigator.geolocation.getCurrentPosition(showPosition, error);
    } else {
      setMessage("Định vị địa lý không được hỗ trợ bởi trình duyệt này");
    }
  };

  const getSurveyList = async (value) => {
    ProductService.get_list_survey(value, "TRUE", "TRUE").then((res) => {
      if (res.code === -1) {
        // localStorage.clear();
        // props.setStage(0);
        console.log("Có lỗi xảy ra, không lấy được dữ liệu");
      } else {
        const data = res.data.list.map((prop) => {
          prop.label = prop.name;
          prop.value = prop._id;
          return prop;
        });
        setSurveyList(data);
      }
    });
  };

  const getProductList = async () => {
    ProductService.get_list_product("SURVEY").then((res) => {
      if (res.code === -1) {
        // localStorage.clear();
        // props.setStage(0);
        console.log("Có lỗi xảy ra, không lấy được dữ liệu");
      } else {
        const data = res.data.map((prop) => {
          prop.label = prop.name;
          prop.value = prop._id;
          prop.icon = HOST_URL_IMAGE + prop.logo;
          return prop;
        });
        setProductList(data);
      }
    });
  };

  const checkLogSurvey = async (productId) => {
    LogService.checkLog(await productId, await props.doctor).then((res) => {
      if (res.data === true) {
        setLogMessage("Bác sĩ đã thực hiện khảo sát này");
      } else {
        setLogMessage("");
      }
    });
  };

  const handleOnChangeProduct = (value) => {
    setSelectProduct(value);
    if (value !== undefined && value !== "") {
      getSurveyList(value);
    }
    if (survey !== "" || survey !== undefined) {
      setSelectSurvey(undefined);
      setLogMessage("");
    }
  };

  const handleOnChangeSurvey = (value) => {
    setSelectSurvey(value);
    var surveyObj = surveyList.find((item) => item._id === value);
    if (surveyObj) {
      setTypeTopup(surveyObj.topupMethod);
    }
    checkLogSurvey(value);
  };

  const handleOnCloseResult = () => {
    setIsShowResult(false);
    setResultList([]);
  };

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <>
      <SurveyResultModal
        productObj={productObj}
        surveyObj={surveyObj}
        survey={survey}
        resultData={resultList}
        visible={isShowResult}
        onCancel={handleOnCloseResult}
      />
      {isShowSurvey && <div className="typeform-content"></div>}
      <div className="product-page-container">
        <div className="product-page-content">
          <div className="product-page product-survey-page">
            <h1>
              <span className="besins-color">BESINS</span> Khảo sát sản phẩm
            </h1>
            <p className="besins-description">
              Chọn sản phẩm và khảo sát để bắt đầu
            </p>
            <div className="product-form">
              <Row>
                <CustomSelect
                  value={product}
                  onChange={handleOnChangeProduct}
                  label="Sản phẩm"
                  allowClear
                  withIcon={true}
                  disabled={false}
                  showSearch
                  option={productList && productList}
                  optionFilterProp="label"
                  placeholder="Chọn sản phẩm"
                />
              </Row>
              <Row>
                <CustomSelect
                  value={survey}
                  onChange={handleOnChangeSurvey}
                  label="Khảo sát"
                  allowClear
                  withIcon={false}
                  disabled={
                    (product !== "" && product !== undefined) ||
                    surveyList.length < 0
                      ? false
                      : true
                  }
                  showSearch
                  placeholder="Chọn khảo sát"
                  option={surveyList}
                  optionFilterProp="label"
                  error={logMessage !== ""}
                  errorText={logMessage !== "" ? logMessage : ""}
                />
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Button
                  onClick={() => getLocation()}
                  type="primary"
                  block
                  disabled={
                    product !== "" &&
                    product !== undefined &&
                    survey !== "" &&
                    survey !== undefined
                      ? false
                      : true
                  }
                >
                  Bắt đầu khảo sát
                </Button>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Button
                  className="btn-otp"
                  onClick={() => props.setStage(1)}
                  block
                  style={{ height: "40px" }}
                >
                  <span className="send-otp">Quay lại</span>{" "}
                </Button>
                {isLoading ? (
                  <div className="location">
                    <Loader
                      type="Oval"
                      color="#0073c4"
                      height={20}
                      width={20}
                    />
                    <div className="location-text">{message}</div>
                  </div>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSurveyPage;
