import React, { useState, useEffect } from "react";
import { Select, Button, Row } from "antd";
import CustomSelect from "../../components/Select";
import {
  viettel,
  mobifone,
  vinaphone,
  gmobile,
  vietnamobile,
} from "../../variables/image";
import LogService from "../../services/log.api";
// import "./style.scss";

type Props = {
  setStage?: any;
  timeEnd?: any;
  stage?: number;
  isSurveyOffline?: boolean
};


const TopupPage = (props: Props) => {
  let params = new URLSearchParams(window.location.search);
  const { setStage, stage, isSurveyOffline } = props;

  const [surveyId] = useState(params.get("survey_id"));
  const [carrierNetwork, setCarrierNetwork] = useState("");
  const carrierNetworkList = [
    {
      id: 1,
      label: "Viettel",
      value: "VT",
      icon: viettel,
    },
    {
      id: 2,
      label: "Mobifone",
      value: "MB",
      icon: mobifone,
    },
    {
      id: 3,
      label: "Vinaphone",
      value: "VP",
      icon: vinaphone,
    },
    {
      id: 4,
      label: "Gmobile",
      value: "GTEL",
      icon: gmobile,
    },
    {
      id: 5,
      label: "Vietnamobile",
      value: "VNM",
      icon: vietnamobile,
    },
  ];

  function submitLog() {
    let data = {
      timeEnd: props.timeEnd,
      carrierNetwork: carrierNetwork,
    };
    let survey_id = surveyId ? surveyId : "";
    console.log(survey_id);
    
    LogService.submitLog(survey_id, data).then((res) => {
      setStage(stage);
      if(isSurveyOffline){
        window.location.href = `?survey_id=${surveyId}`;
      }
    });
  }

  return (
    <div className="product-page-container">
      <div className="thankyou-page-content">
        <div className="product-page thankyou-page">
          <h1>Vui lòng chọn nhà mạng!</h1>
          <div className="product-form">
            <Row style={{ marginTop: 20 }}>
              <CustomSelect
                value={carrierNetwork === "" ? undefined : carrierNetwork}
                onChange={(value: any) => setCarrierNetwork(value)}
                label="Nhà mạng"
                allowClear
                withIcon={true}
                disabled={false}
                showSearch
                option={carrierNetworkList}
                optionFilterProp="label"
                placeholder="Chọn nhà mạng"
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                disabled={carrierNetwork === "" ? true : false}
                onClick={() => submitLog()}
              >
                Xác nhận
              </Button>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopupPage;
