import React from "react";
import LOGO from "../../assets/images/logo.png";
import { Row, Col, Avatar } from "antd";
import "./style.scss";
import getUrlParam from "../../utils/getUrlParam";

type Props = {
  setStage: any;
  stage: any;
  history?: any;
  loginMobile?: boolean;
};

const Header = (props: Props) => {
  const fullName = getUrlParam()["un"] ? getUrlParam()["un"] : localStorage.getItem("fullName");
  const isLogin = localStorage.getItem("salt_token") || getUrlParam()["tk"];
  const logOut = () => {
    localStorage.clear();
    props.history.push("/");
    props.setStage(0);
  };
  return (
    <header>
      <Row>
        <Col span={12}>
          <img src={LOGO} alt="" style={{ height: 80 }} />
        </Col>
        <Col span={12}>
          {isLogin && 
            <div className="user-info">
              <div className="name">
                <p className="name-txt">{fullName}</p>
                {!props.loginMobile &&
                  <p
                    onClick={logOut}
                    className="logout"
                    style={{ float: "right" }}
                  >
                    Đăng xuất
                  </p>
                }
              </div>
              <div className="avatar">
                <Avatar size="default" style={{ background: "#0073c4" }}>
                  {fullName && fullName[0]}
                </Avatar>
              </div>
          </div>
          }
        </Col>
      </Row>
    </header>
  );
};

export default Header;
