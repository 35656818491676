import callLoginApi from "../utils/callLoginApi";
import callApi from "../utils/callApi";

type Authentication = {
  salt: string;
};

const AuthenticationService = {
  login: async (data: Authentication) => {
    const endpoint = `api/user/login_with_salt`;
    const res = await callLoginApi(endpoint, "POST", data);
    return res;
  },
  getOTP: async (data: any) => {
    const endpoint = `api/sms/get_otp`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  checkOTP: async (data: any) => {
    const endpoint = `api/sms/check_otp`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  getOTPSurvey: async (data: any) => {
    const endpoint = `api/sms/get_otp_survey`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  }
};
export default AuthenticationService;
