import callApi from "../utils/callApi";
type Location = {
  latitude: number;
  longitude: number;
};

type Log = {
  medRep: string;
  doctor: string;
  survey: string;
  product: string;
  timeStart: string;
  phone_OTP: string;
  location: Location;
};

const LogService = {
  save_log: async (data: Log) => {
    const endpoint = `api/log/save`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  // submitLog: async (id: string, data: any) => {
  //   const endpoint = `api/log/update/${id}`;
  //   const res = await callApi(endpoint, "PUT", data);
  //   return res;
  // },
  submitLog: async (id: string, data: any) => {
    const endpoint = `api/log/update_sms/${id}`;
    const res = await callApi(endpoint, "PUT", data);
    return res;
  },
  checkLog: async (surveyId: string, doctorId: string) => {
    const endpoint = `api/log/check?survey=${surveyId}&doctor=${doctorId}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getListSuccess: async (data: any) => {
    const endpoint = `api/log/get_success`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  getResultSurvey: async (data: any) => {
    const endpoint = `api/log/get_log_by_product_and_survey?productId=${data.productId}&surveyId=${data.surveyId}&medRepId=${data.medRepId}&status=ALL&limit=999999`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getMyStatistic: () => {
    const endpoint = `api/log/get_my_statistic`;
    const res = callApi(endpoint, "GET");
    return res;
  }
};
export default LogService;
