import axios from "axios";
import { HOST_URL_SEND_SMS } from "../variables/hostTopup";
import { apiKey } from "../variables/configTopup";

export default function callApiTopup(
    endpoint,
    method = "GET",
    data = null,
    form_data = false
) {
    return axios({
        method: method,
        url: `${HOST_URL_SEND_SMS}/${endpoint}`,
        data: data,
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            if (!err.response) {
                // window.location.href = "/login";
            } else {
                const status = err.response.data.status;
                if (status === 403 && window.location.href !== "/login")
                    window.location.href = "/login?redirect=true";
            }
            throw err;
        });
}
