import axios from "axios";
import HOST from "../variables/host";
import getUrlParam from "./getUrlParam.js";

export default function callApi(
  endpoint,
  method = "GET",
  data = null,
  form_data = false
) {
  //This for apis which doesn't need token, do not change
  let token_web = localStorage.getItem("salt_token") ? localStorage.getItem("salt_token") : "";
  let token_mobile = getUrlParam()["tk"];
  let token = token_mobile ? token_mobile : token_web;

  return axios({
    method: method,
    url: `${HOST}/${endpoint}`,
    data: data,
    headers: {
      "api-token": token,
      "content-type": !form_data
        ? "application/json;charset=UTF-8"
        : "multipart/form-data"
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      if (!err.response) {
        // window.location.href = "/login";
      } else {
        const status = err.response.data.status;
        if (status === 403 && window.location.href !== "/login")
          window.location.href = "/login?redirect=true";
      }
      throw err;
    });
}
