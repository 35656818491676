import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import indexRoutes from './routes/index.route';
import 'antd/dist/antd.css';
import 'assets/scss/index.scss';

ReactDOM.render(
  <Router>
    <Switch>
      {indexRoutes.map((route, key) => {
        return (
          <Route path={route.path} component={route.component} key={key} />
        );
      })}
    </Switch>
  </Router>, 
  document.getElementById('root'));

serviceWorker.unregister();
