import React, { useState, useEffect } from "react";
import { Button, Row } from "antd";
import "./style.scss";
import LOGO_THANKYOU from "../../assets/images/logo_thankyou.png";

type Props = {
  setStage?: any;
  loginMobile?: boolean;
};

const ThankYouPage = (props: Props) => {
  useEffect(() => {
    console.log(props.loginMobile);
    console.log(window.location.href.includes("?survey_id"));
  },[props.loginMobile]);
  
  const [isSurveyOnline] = useState(
    window.location.href.includes("?survey_id")
  );

  const logOut = () => {
    localStorage.clear();
    props.setStage(0);
  };

  const reload = () => {
    window.location.reload();
    props.setStage(1);
  };
  

  return (
    <div className="product-page-container">
      <div className="thankyou-page-content">
        <div className="product-page thankyou-page">
          <img src={LOGO_THANKYOU} alt="" />
          <h1>Cảm ơn!</h1>
          <p className="besins-description">
            Cảm ơn Bác sĩ/ Dược sĩ đã dành thời gian thực hiện khảo sát!
          </p>
          <div className="product-form">
            {!isSurveyOnline && (
              <Row style={{ marginTop: 20 }}>
                <Button onClick={() => reload()} type="primary" block>
                  Khảo sát mới
                </Button>
              </Row>
            )}
            {!props.loginMobile &&
              <Row style={{ marginTop: 20 }}>
                <Button
                  className="btn-otp"
                  onClick={logOut}
                  block
                  style={{ height: "40px" }}
                >
                  <span className="send-otp">Đăng xuất</span>{" "}
                </Button>
              </Row>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
