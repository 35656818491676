import React, { useEffect, useState } from "react";
import "./style.scss";
import HOST from "../../variables/host";

type Props = {
  pathRecord?: string;
};

const AudioPlayer = (props: Props) => {
  const { pathRecord } = props;

  return (
    <>
      <div className="audio-player">
        {/* <a target="_blank" href={`/play-audio/${pathRecord}`}>
          Play
        </a> */}
        <audio controls={true} controlsList="nodownload">
          <source src={`${HOST}/images/${pathRecord}`} type="audio/mpeg" />
        </audio>
      </div>
    </>
  );
};

export default AudioPlayer;
