import callApi from "../utils/callApi";

const DoctorService = {
  getList: async (search?: string) => {
    const endpoint = `api/cms/master-list/get_doctor?search=${search || ''}&limit=10`;
    const res: any = await callApi(endpoint);
    return res;
  },
  get: async (id: string) => {
    const endpoint = `shows/${id}`;
    const res = await callApi(endpoint);
    return res;
  },
  checkPhone: async (data: any) => {
    const endpoint = `api/survey/check_phone_number`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  getChannelByDoctor: async (id: any) => {
    const endpoint = `api/survey/getChannelByDoctor?doctorId=${id}`;
    const res = await callApi(endpoint);
    return res;
  },
};

export default DoctorService;
