import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import "./style.scss";
import StatisticTable from "../../components/Table";
import LogService from "../../services/log.api";

const StatisticsPage = props => {
  const [statistic, setStatistic] = useState({});
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    getMyStatistic();
  }, []);

  const getMyStatistic = () => {
    LogService.getMyStatistic().then(async res => {
      if (res.code === -1) {
        console.log("Lỗi xảy ra");
      } else {
        const resData = await res.data;
        const resProduct = await res.data.products;
        setStatistic(resData);
        setProductList(resProduct);
      }
    });
  };

  return (
    <>
      <div className="product-survey-page-container">
        <div className="product-page-container">
          <div className="product-page-content">
            <div className="product-page product-survey-page">
              <h1 style={{ textAlign: "center", marginBottom: 32 }}>
                <span className="besins-color">BESINS</span> Thống kê
              </h1>
              <div className="table-form">
                <Row gutter={[16, 16]}>
                  <Col xs={12} sm={12} md={6}>
                    <div className="box box-done">
                      <div className="box-content">
                        <p className="content-txt">Khảo sát đã thực hiện</p>
                        <p className="content-number">
                          {statistic.surveySum && statistic.surveySum}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="box box-completed">
                      <div className="box-content">
                        <p className="content-txt">Khảo sát thành công</p>
                        <p className="content-number">
                          {statistic.surveySuccess && statistic.surveySuccess}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="box box-topup">
                      <div className="box-content">
                        <p className="content-txt">Khảo sát đã gửi topup</p>
                        <p className="content-number">
                          {statistic.surveyTopupSuccess &&
                            statistic.surveyTopupSuccess}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="box box-failed">
                      <div className="box-content">
                        <p className="content-txt">Khảo sát thất bại</p>
                        <p className="content-number">
                          {statistic.surveyFail && statistic.surveyFail}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="table-section">
                  <StatisticTable resultData={productList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatisticsPage;
