import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Tabs } from "antd";
import THUMBNAIL from "../../assets/images/thumbnail.svg";
import Header from "../../components/Header";
import "./style.scss";
import LoginPage from "../../views/LoginPage";
import ProductPage from "../../views/ProductPage";
import ProductSurveyPage from "../../views/ProductSurveyPage/index";
import PreviewSurveyPage from "../../views/PreviewSurveyPage/index";
import ThankYouPage from "../../views/ThankYouPage";
import StatisticsPage from "../../views/StatisticsPage";
import getUrlParam from "../../utils/getUrlParam.js";
import TopupPage from "../../views/TopupPage";
import { isBrowser } from 'mobile-device-detect';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = {
  children?: any;
  setDoctor?: any;
  setDoctorName?: any;
  setPhoneNum?: any;
  setOtp?: any;
  setSurvey?: any;
  setProduct?: any;
  setLatitude?: any;
  setLongitude?: any;
  setTimeStart?: any;
  setCode?: any;
  history?: any;
};

const AppLayout = (props: Props) => {
  const token_web = localStorage.getItem("salt_token");
  const token_mobile = getUrlParam()["tk"];
  const username_mobile = getUrlParam()["un"];
  const medrep_mobile = getUrlParam()["mr"];
  const token = token_mobile ? token_mobile : token_web;
  const currentStage = token ? 1 : 0;

  const [stage, setStage] = useState(currentStage);
  const [loginMobile, setloginMobile] = useState(
    localStorage.getItem("login_method") === "mobile"
  );
  const [doctor, setDoctor] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [channel, setChannel] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [isWebBrowser, setWebBrowser] = useState(false);

  useEffect(() => {
    const surveyId = getUrlParam()["survey_id"];
    surveyId && setStage(4);
    if (token_mobile) {
      localStorage.setItem("salt_token", token_mobile);
      localStorage.setItem("fullName", username_mobile);
      localStorage.setItem("medRep", medrep_mobile);
      localStorage.setItem("login_method", "mobile");
      setloginMobile(true);
    }
  }, [medrep_mobile, token_mobile, username_mobile]);

  const renderPage = () => {
    switch (stage) {
      case 0:
        return <LoginPage setStage={setStage} />;
      case 1:
        return (
          <ProductPage
            setStage={setStage}
            setDoctor={setDoctor}
            setDoctorName={setDoctorName}
            setPhoneNum={setPhoneNum}
            setChannel={setChannel}
            phoneOtp={phoneNum}
          />
        );
      case 2:
        return (
          <ProductSurveyPage
            setStage={setStage}
            doctor={doctor}
            phoneOtp={phoneNum}
            doctorName={doctorName}
            channel={channel}
            setTimeEnd={setTimeEnd}
          />
        );
      case 4:
        return <ThankYouPage loginMobile={loginMobile} setStage={setStage} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (token_mobile) {
      setWebBrowser(false)
    }else{
      setWebBrowser(isBrowser)
    }
  },[])

  return (
    <Layout>
      <Content>
        <Row>
          <Col md={24} xl={14}>
            <Header
              loginMobile={loginMobile}
              history={props.history}
              stage={stage}
              setStage={setStage}
            />
            {isWebBrowser && (
                <div className="content flex-center">
                  <h3>Your device is not valid</h3>
                </div>
            )}
            {!isWebBrowser && (
              <>
                 {token && (
                  <>
                    {stage < 3 && (
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Khảo sát sản phẩm" key="1">
                          <div
                            className="content"
                            style={{ height: `calc(100vh - 220px)` }}
                          >
                            {renderPage()}
                          </div>
                        </TabPane>
                        <TabPane tab="Kết quả khảo sát" key="2">
                          <div
                            className="content"
                            style={{ height: `calc(100vh - 190px)` }}
                          >
                            <PreviewSurveyPage
                              doctor={doctor}
                              setStage={setStage}
                              phoneOtp={phoneNum}
                              doctorName={doctorName}
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Thống kê" key="3">
                          <div
                            className="content"
                            style={{ height: `calc(100vh - 190px)` }}
                          >
                            <StatisticsPage />
                          </div>
                        </TabPane>
                      </Tabs>
                    )}
                    {stage === 3 && (
                      <TopupPage
                        setStage={setStage}
                        timeEnd={timeEnd}
                        stage={4}
                        isSurveyOffline
                      />
                    )}
                    {stage === 4 && (
                      <ThankYouPage loginMobile={loginMobile} setStage={setStage} />
                    )}
                  </>
                )}
                {!token && (
                  <div
                    className="content"
                    style={{ height: `calc(100vh - 190px)` }}
                  >
                    <LoginPage setStage={setStage} />
                  </div>
                )}
              </>
            )}
          </Col>
          <Col md={24} xl={10}>
            <div className="background">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${THUMBNAIL})`,
                  height: `100vh`,
                }}
              />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AppLayout;
