import React from "react";
import "./style.scss";
import { Input } from "antd";
export type InputSize = "default" | "small" | "large";
export type InputType = "text" | "number" | "textarea";

type Props = {
  value?: any;
  size?: InputSize;
  placeholder?: string;
  disabled?: boolean;
  type?: InputType;
  label?: string;
  pattern?: string;
  maxLength?: number;
  onChange?: any;
  readOnly?: boolean;
};

const CustomInput = (props: Props) => {
  return (
    <div className="custom-input">
      <label className="custom-input-label">{props.label}</label>
      <Input
        value={props.value}
        size={props.size}
        placeholder={props.placeholder}
        disabled={props.disabled}
        pattern={props.pattern}
        maxLength={props.maxLength}
        type={props.type}
        onChange={event => props.onChange(event.target.value)}
        readOnly={props.readOnly}
        style={
          props.readOnly ? { background: "#F5F6F7" } : { background: "#FFFFFF" }
        }
      />
    </div>
  );
};

export default CustomInput;
