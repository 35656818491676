const doctor1 = "/image/doctor/doctor1.png";
const refresh = "/image/doctor/refresh_24px.png";
const avatar = "/image/doctor/avatar.png";
const product = "/image/doctor/product1.png";

const viettel = "/image/card/viettel.png";
const mobifone = "/image/card/mobifone.png";
const gmobile = "/image/card/gmobile.jpg";
const vietnamobile = "/image/card/vietnamobile.jpg";
const vinaphone = "/image/card/vinaphone.png";
export { avatar, doctor1, refresh, product, viettel, mobifone, gmobile, vietnamobile, vinaphone };
