import React from "react";
import "./style.scss";
import { Select, Avatar, Form, Tooltip } from "antd";
import getPlacements from "antd/lib/tooltip/placements";
const { Option } = Select;

type Props = {
  allowClear: boolean;
  withIcon: boolean;
  placeholder: string;
  label: string;
  option: Array<any>;
  disabled: boolean;
  showSearch: boolean;
  error: boolean;
  errorText: string;
  onChange: any;
  onSearch?: any;
  value?: any;
  optionFilterProp?: string;
  filterOption?: boolean
};

const CustomSelect = (props: Props) => {
  const {
    allowClear,
    withIcon,
    placeholder,
    label,
    disabled,
    showSearch,
    option,
    error,
    errorText,
    onChange,
    onSearch,
    value,
    optionFilterProp,
    filterOption
  } = props;

  const widthScreen = window.innerWidth;

  function limitTitle(title: string, number: number) {
    if (title.length < number) {
      return <span style={{ color: "#172B4D" }}>{title}</span>;
    } else {
      return (
        <Tooltip title={title} placement="bottom">
          <span style={{ color: "#172B4D" }}>
            {title.substring(0, number)}...
          </span>
        </Tooltip>
      );
    }
  }
  return (
    <div className="custom-select">
      <Form>
        <Form.Item validateStatus={error ? "error" : ""} help={errorText}>
          <label className="select-label">{label}</label>
          <Select
            value={value}
            showSearch={showSearch}
            placeholder={placeholder}
            allowClear={allowClear}
            disabled={disabled}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            optionFilterProp={optionFilterProp}
            size="large"
            style={{ width: "100%" }}
          >
            {option.map((item) => (
              <Option
                className="option-item"
                key={item.value}
                value={item.value}
                label={item.label}
              >
                <div
                  className="select-option"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {widthScreen >= 413 && withIcon ? (
                    <>
                      {item.icon ? (
                        <Avatar
                          src={item.icon}
                          size="default"
                          style={{ marginRight: "8px" }}
                        />
                      ) : (
                        <Avatar
                          size="default"
                          style={{ background: "#0073c4", marginRight: "8px" }}
                        >
                          {item.label && item.label[0]}
                        </Avatar>
                      )}
                      <span className="option-label">
                        {limitTitle(item.label, 65)}
                      </span>
                    </>
                  ) : (
                    <span className="option-label">{item.label}</span>
                  )}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CustomSelect;

CustomSelect.defaultProps = {
  allowClear: true,
  withIcon: false,
  className: "",
  placeholder: "",
  label: "",
  disabled: false,
  showSearch: true,
  error: false,
  errorText: "",
};
