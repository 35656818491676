import React, { useState, useEffect } from "react";
import CustomSelect from "../../components/Select";
import SurveyResultModal from "../../components/Modal";
import { Button, Row } from "antd";
import "./style.scss";
import ProductService from "../../services/product.api";
import LogService from "../../services/log.api";
// import * as typeformEmbed from "@typeform/embed";
import HOST_URL_IMAGE from "../../variables/hostUrl";
// import { defaultAmount } from "../../variables/configTopup";

const PreviewSurveyPage = (props) => {
  const [product, setSelectProduct] = useState();
  const [productObj, setProductObj] = useState({});
  const [surveyObj, setSurveyObj] = useState({});

  const [survey, setSelectSurvey] = useState();
  const [surveyList, setSurveyList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [isSelect, setIsSelect] = useState(false);

  const [resultList, setResultList] = useState([]);

  const [isShowResult, setIsShowResult] = useState(false);
  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const getSurveyList = async (value) => {
    ProductService.get_list_survey(value, "ALL", "TRUE").then((res) => {
      if (res.code === -1) {
        // props.setStage(1);
        console.log("Có lỗi xảy ra");
      } else {
        const data = res.data.list.map((prop) => {
          prop.label = prop.name;
          prop.value = prop._id;
          return prop;
        });
        setSurveyList(data);
      }
    });
  };

  const getProductList = async () => {
    ProductService.get_list_product("RESULT_SURVEY").then((res) => {
      if (res.code === -1) {
        // props.setStage(0);
        console.log("Có lỗi xảy ra");
      } else {
        const data = res.data.map((prop) => {
          prop.label = prop.name;
          prop.value = prop._id;
          prop.icon = HOST_URL_IMAGE + prop.logo;
          return prop;
        });
        setProductList(data);
      }
    });
  };

  const handleOnChangeProduct = (value) => {
    setSelectProduct(value);
    setIsSelect(true);
    if (value !== undefined && value !== "") {
      getSurveyList(value);
    }
    if (survey !== "" || survey !== undefined) {
      setSelectSurvey(undefined);
    }
  };

  const handleOnChangeSurvey = (value) => {
    setSelectSurvey(value);
  };

  const getSurveyResult = () => {
    // const data = {
    //   product,
    //   list_survey: survey ? [survey] : [],
    //   list_doctor: [],
    //   list_medRep: [],
    //   fromDate: "",
    //   toDate: "",
    //   search: "",
    //   sort: -1
    // };
    const data = {
      productId: product ? product : "",
      surveyId: survey ? survey : "",
      medRepId: localStorage.getItem("medRep"),
    };
    var productObj = productList.find((item) => item._id === product);
    var surveyObj = surveyList.find((item) => item._id === survey);
    setProductObj(productObj);
    setSurveyObj(surveyObj);
    setIsLoadingResult(true);
    LogService.getResultSurvey(data).then(async (res) => {
      if (res.code === -1) {
        console.log("Lỗi xảy ra");
      } else {
        const resultList = await res.data.docs;
        setResultList(resultList);
        setIsLoadingResult(false);
      }
    });
  };

  const handleOnShowResult = async () => {
    getSurveyResult();
    setIsShowResult(true);
  };

  const handleOnCloseResult = () => {
    setIsShowResult(false);
    setResultList([]);
  };

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <>
      <SurveyResultModal
        productObj={productObj}
        surveyObj={surveyObj}
        survey={survey}
        loading={isLoadingResult}
        resultData={resultList}
        visible={isShowResult}
        onCancel={handleOnCloseResult}
      />
      <div className="product-survey-page-container">
        <div className="product-page-container">
          <div className="product-page-content">
            <div className="product-page product-survey-page">
              <h1>
                <span className="besins-color">BESINS</span> Kết quả khảo sát
              </h1>
              <p className="besins-description">
                Nhập các trường thông tin để xem kết quả khảo sát
              </p>
              <div className="product-form">
                <Row>
                  <CustomSelect
                    value={product}
                    onChange={handleOnChangeProduct}
                    label="Sản phẩm"
                    allowClear
                    withIcon={true}
                    disabled={false}
                    showSearch
                    option={productList && productList}
                    optionFilterProp="label"
                    placeholder="Chọn sản phẩm"
                  />
                </Row>
                <Row>
                  <CustomSelect
                    value={survey}
                    onChange={handleOnChangeSurvey}
                    label="Khảo sát"
                    allowClear
                    withIcon={false}
                    disabled={
                      (product !== "" && product !== undefined) ||
                      surveyList.length < 0
                        ? false
                        : true
                    }
                    showSearch
                    placeholder="Chọn khảo sát"
                    option={surveyList}
                    optionFilterProp="label"
                  />
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Button
                    onClick={() => handleOnShowResult()}
                    className="btn-otp"
                    block
                    style={{ height: "40px" }}
                    disabled={
                      (product !== "" && product !== undefined) ||
                      (survey !== "" && survey !== undefined)
                        ? false
                        : true
                    }
                  >
                    Xem kết quả khảo sát
                  </Button>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewSurveyPage;
