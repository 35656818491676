import callApi from "../utils/callApi";
import callApiTopup from "../utils/callApiTopup";
import callApiSms from "../utils/callApiSms";

import { defaultAmount, sign } from "../variables/configTopup";

const ProductService = {
  get_list_survey: async (productId: string, isActive: string, isInTime: string) => {
    const endpoint = `api/survey/get_by_product?product=${productId}&isActive=${isActive}&isInTime=${isInTime}`;
    const res: any = await callApi(endpoint, "GET");
    return res;
  },
  get_list_product: async (value:any) => {
    const data = (value !="RESULT_SURVEY") ? "TRUE" : "FALSE"
    const endpoint = `api/product/my_assigned_product?isInTime=${data}`;
    const res: any = await callApi(endpoint, "GET");
    return res;
  },
  post_topup: async (phone: number) => {
    const endpoint = `topup?request_id=12345&phone_number=${phone}&amount=${defaultAmount}&request_time=20181215235959&partner_name=vivas&sign=${sign}`;
    const res: any = await callApiTopup(endpoint, "POST");
    return res;
  },
  send_sms: async (data: any) => {
    const endpoint = `sendsms`;
    const res: any = await callApiSms(endpoint, "POST");
    return res;
  },
  sendOTP: async (data: any) => {
    const endpoint = `api/sms/get_otp`;
    const res: any = await callApi(endpoint, "POST", data);
    return res;
  },
  checkOTP: async (data: any) => {
    const endpoint = `api/sms/check_otp`;
    const res: any = await callApi(endpoint, "POST", data);
    return res;
  },
  get_survey_info: async (id: any) => {
    const endpoint = `api/survey/get_survey_without_token/${id}`;
    const res: any = await callApi(endpoint, "GET");
    return res.data;
  },
  sendOTPSurvey: async (data: any) => {
    const endpoint = `api/sms/get_otp_survey`;
    const res: any = await callApi(endpoint, "POST", data);
    return res;
  },
};
export default ProductService;
