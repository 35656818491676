 function getUrlParam(){
	var vars = {tk: "", un: "", mr: "", survey_id: ""};
	window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
		vars[key] = decodeURIComponent(value);
		return vars[key];
  });
  return vars;
}
export default getUrlParam;