import React, { useState } from "react";
import { Row, Col, Layout, Tabs } from "antd";
import THUMBNAIL from "../../../assets/images/thumbnail.svg";
import Header from "../../../components/Header";
import "./style.scss";
import ThankYouPage from "../../../views/ThankYouPage";
import SurveyOnlinePage from "../../../views/SurveyOnlinePage/index";
import TopupPage from "../../../views/TopupPage";

const { Content } = Layout;

type Props = {
  children?: any;
  setDoctor?: any;
  setDoctorName?: any;
  setPhoneNum?: any;
  setOtp?: any;
  setSurvey?: any;
  setProduct?: any;
  setLatitude?: any;
  setLongitude?: any;
  setTimeStart?: any;
  setCode?: any;
  history?: any;
};

const SurveyOnlineLayout = (props: Props) => {
  const [stage, setStage] = useState(0);
  const [timeEnd, setTimeEnd] = useState("")

  const renderPage = () => {
    switch (stage) {
      case 0:
        return <SurveyOnlinePage setStage={setStage} setTimeEnd={setTimeEnd}/>;
        case 1:
          return <TopupPage setStage={setStage} timeEnd={timeEnd} stage={2}/>;
      case 2:
        return <ThankYouPage setStage={setStage} />;
      default:
        return "";
    }
  };
  return (
    <Layout>
      <Content>
        <Row>
          <Col md={24} xl={14}>
            <Header history={props.history} stage={stage} setStage={setStage} />
            {renderPage()}
          </Col>
          <Col md={24} xl={10}>
            <div className="background">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${THUMBNAIL})`,
                  height: "100vh",
                }}
              />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default SurveyOnlineLayout;
