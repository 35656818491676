import AppLayout from "../layouts/app";
import SurveyOnlineLayout from "../layouts/app/survey-online";

const indexRoutes = [
  {
    path: "/survey-online/:survey",
    exact: true,
    component: SurveyOnlineLayout,
  },
  {
    path: "/",
    exact: true,
    component: AppLayout,
  },
  {
    path: "*",
    component: AppLayout,
  },
];

export default indexRoutes;
