import React, { useState, useEffect } from "react";
// import "./style.scss";
import { Table, Input } from "antd";

const { Search } = Input;

const columnsData = [
  {
    title: "Tên khảo sát",
    dataIndex: "name",
    key: "1",
    width: "70%"
  },
  {
    title: "Thành công",
    dataIndex: "surveySuccess",
    key: "2",
    align: "right",
    width: "30%"
  }
];

const change_alias = alias => {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
};

const StatisticTable = props => {
  const { resultData } = props;
  const [dataSource, setDataSource] = useState(resultData);

  useEffect(() => {
    setDataSource(resultData);
  }, [resultData]);

  const handleOnSearch = e => {
    const currValue = change_alias(e.target.value);
    const dataSourceFor = dataSource;
    const filteredData = dataSourceFor.filter(entry =>
      change_alias(entry.name).includes(currValue)
    );
    if (currValue !== "") {
      setDataSource(filteredData);
    } else {
      setDataSource(props.resultData);
    }
  };

  return (
    <div className="custom-table">
      <Search
        className="input-search"
        placeholder="Tìm kiếm bằng từ khóa"
        size="large"
        onChange={handleOnSearch}
      />
      <Table columns={columnsData} dataSource={dataSource} pagination={false} />
    </div>
  );
};

export default StatisticTable;

StatisticTable.defaultProps = {};
